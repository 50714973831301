import { v4 as uuidv4 } from 'uuid'
import { ref } from 'vue'
import { defineStore } from 'pinia'
import type { NotificationState, NotificationPayload } from '@/stores/notificationsStore/types'

const DEFAULT_NOTIFICATION_TIMEOUT = 5000

export const useNotificationsStore = defineStore('notificationsStore', () => {
  const notifications = ref<NotificationState[]>([])

  const hideNotification = (id: string) => {
    const notificationIndex = notifications.value.findIndex(notification => notification.id === id)

    if (notificationIndex < 0) return

    clearTimeout(notifications.value[notificationIndex].timeoutReference)

    const [hiddenNotification] = notifications.value.splice(notificationIndex, 1)

    return hiddenNotification
  }

  const showNotification = (payload: NotificationPayload) => {
    const timeout = payload.timeout || DEFAULT_NOTIFICATION_TIMEOUT
    let timeoutReference: NodeJS.Timeout | string = ''
    const id = `notification-${uuidv4()}`

    timeoutReference = setTimeout(() => {
      hideNotification(id)
    }, timeout)

    const notificationToPush = {
      ...payload,
      id,
      timeout,
      variant: payload.variant || 'success',
      timeoutReference,
      createdAt: new Date().toISOString()
    }

    notifications.value.push(notificationToPush)

    return notificationToPush
  }

  return {
    notifications,
    showNotification,
    hideNotification
  }
})
