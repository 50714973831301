<script lang="ts" setup>
import AppToast from '@/components/App/Toast/index.vue'
import { useNotificationsStore } from '@/stores/notificationsStore'

const { notifications, hideNotification } = useNotificationsStore()
</script>

<script lang="ts">
export default {
  name: 'AppNotificationsTrack'
}
</script>

<template>
  <div
    id="app-notifications-track"
    class="fixed flex flex-col-reverse items-end gap-8 top-20 right-0 z-60">
    <TransitionGroup name="list">
      <AppToast
        v-for="notification in notifications"
        :id="notification.id"
        :key="notification.id"
        :title="notification.title"
        :description="notification.description"
        :variant="notification.variant"
        @close="hideNotification(notification.id)" />
    </TransitionGroup>
  </div>
</template>
