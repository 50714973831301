<script lang="ts" setup>
import RoomieToast from '@lahaus-roomie/roomie/src/components/RoomieToast/index.vue'
import type { Props } from './types'

defineProps<Props>()

const emit = defineEmits(['close'])
</script>

<script lang="ts">
export default {
  name: 'AppToast'
}
</script>

<template>
  <RoomieToast
    :title
    :description
    :variant
    is-visible
    :time-to-hide="50_000"
    class="!relative !my-0"
    @toast-closed="() => emit('close')" />
</template>
